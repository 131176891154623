import {
  Button,
  Text,
  Spinner,
  useToast,
  Stack,
  useDisclosure,
  Checkbox,
  Heading,
  VStack,
  FormControl,
  Switch,
} from '@chakra-ui/react'
import { ExportToCsv } from 'export-to-csv'
import moment from 'moment-timezone'
import { FaFileDownload } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import { toSnakeCase } from '../../utils/string_utils'
import { CSVType, ReportType, getStatusLabel } from '../../utils/constants'
import { DOPPEL_CYBER_BLUE, DOPPEL_CYBER_BLUE_SHADE } from '../../utils/style'
import SideDrawer from '../shared/side_drawer'
import DoppelDefaultButton from '../shared/doppel_default_button'
import { useIsEmployeeView } from '../../hooks/id_token_claims'
import SpoofReportsTableFilterBar, {
  FilterBarStyle,
} from '../web2/spoof_reports_table_filter_bar'
import ReportsTableFilterBar from '../collection_reports_table/reports_table_filter_bar'
import DoppelFormLabel from '../shared/forms/doppel_form_label'
import { CSV_OPTIONS } from '@/utils/csv_generation/report_csv_constants'
import ProtectedAssetsTableFilterBar from '../assets/protected_assets_table_filter_bar'

const csvExporter = new ExportToCsv(CSV_OPTIONS)

/**
 * Download a CSV file with the current applied filters and sorting mechanism, downloads an unpaged number of rows
 */
const GenerateCSVButton = ({
  type,
  statuses,
  currentStatuses,
  getQuery,
  orderBy,
  processData,
  whereClause,
  internalOnly = false,
}) => {
  const [fetchData, { loading, error, data }] = getQuery({
    notifyOnNetworkStatusChange: true,
  })

  const [selectedStatuses, setSelectedStatuses] = useState(currentStatuses)
  const [isDetailed, setisDetailed] = useState(false)
  const [isEmployeeView] = useIsEmployeeView()

  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleToggle = () => setisDetailed(!isDetailed)

  useEffect(() => {
    currentStatuses && setSelectedStatuses(currentStatuses)
  }, [currentStatuses])

  const downloadCSV = async () => {
    const input: any = {
      variables: { orderBy: orderBy },
    }

    let key
    if (type == ReportType.NFTS) {
      key = 'collectionReportsWhere'
    } else if (type != CSVType.PROTECTED_ASSET) {
      key = 'spoofingReportsWhere'
    } else {
      key = 'protectedAssetsWhere'
    }

    input['variables'][key] = whereClause(selectedStatuses)
    fetchData(input)
    onClickDownload()
  }

  const onClickDownload = async () => {
    if (!isOpen) {
      return
    }

    if (data && !error && (!rawRows || rawRows.length == 0)) {
      toast({
        title: 'An error occurred',
        description: 'No items were found for the current filters',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
    }

    if (rawRows && rawRows.length > 0) {
      const processedRows = processData(rawRows, isDetailed, isEmployeeView)
      const options = csvExporter.options

      options.filename =
        'doppel_' +
        toSnakeCase(type) +
        '_' +
        moment.utc().tz(moment.tz.guess()).format('MMM_Do_YYYY')

      csvExporter.options = options
      csvExporter.generateCsv(processedRows)
    }
  }

  let rawRows
  if (type === ReportType.NFTS) {
    rawRows = data?.collection_reports
  } else if (type === CSVType.PROTECTED_ASSET) {
    rawRows = data?.protected_assets
  } else {
    rawRows = data?.spoofing_reports
  }

  const filterBarComponent = ({ type }) => {
    if (type == ReportType.NFTS) {
      return <ReportsTableFilterBar style={FilterBarStyle.VERTICAL} />
    } else if (type == CSVType.PROTECTED_ASSET) {
      return <ProtectedAssetsTableFilterBar style={FilterBarStyle.VERTICAL} />
    } else {
      return (
        <SpoofReportsTableFilterBar
          isOrgUnifiedView={false}
          style={FilterBarStyle.VERTICAL}
        />
      )
    }
  }

  useEffect(() => {
    if (error) {
      toast({
        title: 'An error occurred',
        description: 'There was an error fetching the data for the csv report.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }, [error])

  useEffect(() => {
    onClickDownload()
  }, [data || rawRows])

  let buttonContent = <Text fontSize={12}>Download CSV</Text>

  if (loading) {
    buttonContent = <Spinner size="sm" />
  }

  return (
    <>
      <DoppelDefaultButton
        isInternal={internalOnly}
        leftIcon={<FaFileDownload size="14px" />}
        marginRight={4}
        onClick={onOpen}
      >
        Generate CSV {internalOnly && '(Old)'}
      </DoppelDefaultButton>

      <SideDrawer
        isOpen={isOpen}
        onClose={() => {
          onClose()
        }}
        size="lg"
      >
        <Stack spacing="24px">
          <Heading as="h2" size="md">
            Generate CSV
          </Heading>

          {statuses.length > 0 && (
            <Stack spacing="24px">
              <Heading as="h3" size="sm">
                Select statuses to include
              </Heading>

              <VStack align="start">
                {statuses.map((status) => (
                  <Checkbox
                    isChecked={selectedStatuses.includes(status)}
                    key={status}
                    onChange={(e) => {
                      // Toggle the selected status
                      if (e.target.checked) {
                        setSelectedStatuses([...selectedStatuses, status])
                      } else {
                        setSelectedStatuses(
                          selectedStatuses.filter((s) => s !== status),
                        )
                      }
                    }}
                  >
                    {getStatusLabel(status, type)}
                  </Checkbox>
                ))}
              </VStack>
            </Stack>
          )}

          <Heading as="h3" size="sm">
            Select filters to apply
          </Heading>

          {filterBarComponent({ type })}

          {type == ReportType.DOMAINS && (
            <FormControl>
              <Stack>
                <DoppelFormLabel
                  formLabel="Detailed Rows"
                  helperText="Expanded rows accounting for protocol and subdomain variations"
                />

                <Switch isChecked={isDetailed} onChange={handleToggle} />
              </Stack>
            </FormControl>
          )}

          <Button
            _hover={{ background: DOPPEL_CYBER_BLUE_SHADE }}
            bgColor={DOPPEL_CYBER_BLUE}
            color={'white'}
            fontSize="13px"
            leftIcon={<FaFileDownload size="14px" />}
            onClick={downloadCSV}
            width={'250px'}
          >
            {buttonContent}
          </Button>
        </Stack>
      </SideDrawer>
    </>
  )
}

export default GenerateCSVButton
